<template>
  <div class="page page--contact contact">
    <div class="page__block">
      <h2>{{ $t('contact.title') }}</h2>
      <p>{{ $t('contact.description') }}</p>
      <p><b>Huur een Stuur BV</b></p>
      <table>
        <tr>
          <td>{{$t('contact.address')}}</td>
          <td><p>Wiedauwkaai 98<br>9000 {{ $t('general.gent') }}<br>{{ $t('general.be') }}</p></td>
        </tr>
        <tr>
          <td>{{$t('contact.tel')}}</td>
          <td><p><a href="tel:+3290000001">+3290000001</a><br />({{$t('contact.booking_new')}}, {{$t('contact.pay')}} - <router-link to="/phone">info</router-link>)</p><p><a href="tel:+3251702645">+3251702645</a><br>({{$t('contact.booking_ext')}})</p></td>
        </tr>
        <tr>
          <td>{{$t('contact.email')}}</td>
          <td><a href="mailto:contact@billo.be">contact@billo.be</a></td>
        </tr>
        <tr>
          <td>{{$t('contact.vat')}}</td>
          <td>BE 0450 825 019</td>
        </tr>
        <tr>
          <td>{{$t('contact.companynr')}}</td>
          <td>0450 825 019</td>
        </tr>
      </table>
    </div>
    <div class="page__block">
      <h2>{{ $t('contact.complaint') }}</h2>
      <div v-html="$t('contact.complaints')"></div>
      <div v-html="$t('contact.complaints2')"></div>
    </div>
    <div class="page__block">
      <h2>{{$t('partner.texttitle')}}</h2>
      <p>{{$t('partner.text')}} <a target="_blank" :href="$t('partner.link')">{{$t('partner.request')}}</a>{{$t('partner.text2')}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",

  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>